<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="600" persistent>
        <v-card flat class="card">
            <v-toolbar outlined flat>
                <v-toolbar-name>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-name>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>

                            <v-row>
                                <v-img :src="$url+path"/>
                                <v-col cols="12">
                                    <!-- <MultipartFileUpload endPoint="UploadFiles" buttonText="رفع صورة الطبيب" formData="files"/> -->
                                    <v-btn color="primary" class="text-none mr-2 btn" :loading="uploading" @click="onButtonClick" large>
                                        <v-icon left>
                                            cloud_upload
                                        </v-icon>
                                        <h3>رفع صورة الطبيب</h3>
                                    </v-btn>
                                    <input ref="uploader" class="d-none" type="file" :accept="accept" @change="onFileChanged">
                                </v-col>
                                <aTextField v-model="item.fullName" label="اسم الطبيب" :cols="12" :md="12" :required="true" :fieldname="true" />
                                <aTextField v-model="item.description" label="وصف مختصر" :cols="12" :md="12" :required="true" :fieldname="true" />
                                <aTextField v-model="item.phoneNumber" label="رقم هاتف الطبيب" :cols="12" :md="12" :required="true" :fieldname="true" />
                                <!-- <v-checkbox @click="item.clinicId = ''" v-show="showClinic">
                                    عرض العيادة
                                </v-checkbox> -->
                                <v-btn small class="mx-2" icon color="error" @click="item.clinicId = '' , item.clinic=null">
                                    <v-icon>
                                        fi fi-rr-circle-xmark
                                    </v-icon>
                                </v-btn>
                                <strong class="mt-1">
                                ازالة من العيادة

                                </strong>
                                <aAutoComplete :loading="$global.state.loading" :fieldTitle="false" v-model="item.clinicId" :items="clinics" item-text="name" item-value="id" title="العيادة" :cols="12" :md="12" :required="true" />
                                <v-col>
                                    <v-radio-group v-model="item.isMorning">
                                        <v-radio
                                          label="صباحي"
                                          :value="true"
                                        ></v-radio>
                                        <v-radio
                                          label="مسائي"
                                          :value="false"
                                        ></v-radio>
                                      </v-radio-group>
                                </v-col>

                                <v-col cols="12">
                                    <h4>عن الطبيب</h4>
                                    <VueEditor v-model="item.content"/>
                                </v-col>
                                <!-- <h3>وقت الحضور</h3>
                                <v-time-picker color="primary" full-width label="test" v-model="item.fromTime" ampm-in-title format="ampm"></v-time-picker>
                                <h3>وقت المغادرة</h3>
                                <v-time-picker color="primary" full-width v-model="item.toTime" ampm-in-title format="ampm"></v-time-picker> -->
                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid || uploading" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {

    components:{
        // MultipartFileUpload: ()=> import('@/components/MultipartFileUpload'), 
        VueEditor
    },

    data() {
        return {
            item: {},
            search: '',
            valid: false,
            fromTime: '',
            toTime: '',
            clinics: [],
            path: null,
            uploading: false
        }
    },
 
    created() {
        this.reset()
        this.getClinics()

        this.$eventBus.$on('fill-fields', () => {
            this.item = this.$store.state.itemDetails
            this.path = this.$store.state.itemDetails.imageUrl
            this.item.clinicId = this.$store.state.itemDetails.clinic.id
        })  

    },

    methods: {

        onButtonClick() {
            // this.isSelecting = true
            window.addEventListener('focus', () => {
                // this.isSelecting = false
            }, {
                once: true
            })

            this.$refs.uploader.click()
        },

        async onFileChanged(e) {

            this.uploading = true;
            try {
                let formData = new FormData();
                // formData.append('files', file);
                formData.append("files", e.target.files[0]);
                let res = await this.$http.post("UploadFiles", formData);
                this.path = res.data[0]
                this.$service.success("تم رفع الملف بنجاح");
                // service.refresh('refresh')
            } catch (err) {
                console.log(err);
                this.$service.faild(err.response.data.message);
            } finally {
                this.uploading = false;
            }

        },

      
        async getClinics() {
            this.loading = true
            try {
                let res = await this.$http.get(`Clinic`)
                this.clinics = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        reset() {
            this.item = {
                "fullName": "",
                "phoneNumber": "",
                "isMorning": true,
                "clinicId": "",
            }
        },

        submit() {
            this.item.type = this.$route.meta.type
            this.item.imageUrl = this.path

            // let [hour, minute] = this.fromTime.split(':').map(Number);
            // this.item.fromTime.hour = hour
            // this.item.fromTime.minute = minute
            // let [hour2, minute2] = this.toTime.split(':').map(Number);
            // this.item.toTime.hour = hour2
            // this.item.toTime.minute = minute2

            setTimeout(() => {
                if (this.$store.state.itemDetails.dialogType == 'add') {
                    this.addItem()
                } else {
                    this.editItem()
                }
            }, 10);
        },

        addItem() {
            this.$global.state.loading = true
            this.$http.post(`${this.$route.meta.endPoint}`, this.item).then(res => {
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            }).catch(err => {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.$http.put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        close() {
            this.$store.commit('setDialog')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
            this.item.imageUrl = null
            this.path = null
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
